@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fill-screen{
  min-height: 140vh;

}

@media (width >= 800px){

  html::-webkit-scrollbar {
    width: 10px;
  }
  html::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #505050;
  }
  html::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: #e7e7e7;
    border:  solid #cacaca;
  }
}
@media (width < 800px){
  html::-webkit-scrollbar{
    overflow: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none; 
  }
}

.popup-container{
  width: 250px;
  min-height: 85px;
  border-radius:  8px;
  border: solid 2px #6b6b6e ;
  padding: 1rem;
  margin: 1rem;
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #18181B ;
  position:fixed;
  bottom:1rem;
  right:1rem;
  z-index: 5;

}
.pop-up{
  animation: popUp .7s ease-in-out;
}
.pop-down{
  animation: popDown .7s ease-in-out;
  animation-fill-mode: forwards;
}
@keyframes popUp {
  0%{
    transform: scale(0.1);
    transform: translateY(150%);
  }
  100%{
    transform: scale(1);
    transform: translateY(0%);
  }
}
@keyframes popDown {
  0%{
    transform: scale(1);
    transform: translateY(0%);
  }
  20%{
    transform: scale(1.2);
    transform: translateY(-10%);
  }
  100%{
    transform: scale(.1);
    transform: translateY(200%);
  }
}

.popup-text{
  width: 100%;
  padding-left: 15px;;
}

#grid {
  z-index: 2;
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(75px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(75px, 1fr));
  justify-content: center;
  background-color: #9b9b9b;
  .grid-item {
    z-index: 5;
    min-width: 100%;
    min-height: 100%;
    background-color: rgb(240, 240, 240);
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size-adjust: auto;
    
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      outline: 1px solid black;
      display: block;
    }
    /* &:hover {
      opacity: 0.8;
      background-color: rgb(75, 75, 75);
    } */
  }
}
.grid-item:hover{
  opacity: .1;
  background-color: rgb(196, 45, 45);
}
@font-face {
  font-family: "Goblin";
  src: local("Goblin") url("./assets/a-goblin-appears-font/AGoblinAppears-o2aV.ttf") format('truetype');
}

.font{
  font-family: monospace;
}
.logan-henig{
  display:flex;
  justify-content: flex-start;
  align-items: center;
  position:absolute;
  flex-direction: column;
  inset: 0;
  margin: auto;
  margin-top: 35vh;


  color: white
}
.logan-henig-background{
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  max-height: 10vh;
  min-height: 50px;
  min-width:400px;
  max-width: max-content;
  padding-left: 2vw;
  padding-right:2vw;
  background-color:  #353535;
  border: #b7dfff;
  border: solid 2px;
  border-radius: 140px;
  font-family: "Goblin";
  font-size:1.2rem;
}
.clickme-chip{
  display: flex;
  align-items: center;
  text-align: center;
  width: 10rem;
  height: 3rem;
  max-width: 35rem;
  font-family: monospace;
}
@media (min-width: 992px) {
  .logan-henig-background {
    font-size: 4rem;
  }
  .clickme-chip{

    height: 4rem;
  }
  
} 
@media (max-width: 422px) {
  .logan-henig-background {
    min-width:200px;
    font-size: .8rem;
  }
  .clickme-chip{
    width: 1rem;
    max-height: 2rem;
    font-size: small;
  }
} 
.flex-container{
  height:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-height: fit-content;
}
.click-me{
  position: relative;
  top: 3vh;
  left: 15vw;
}

.hidden-1 {
  animation: fadeOut1 forwards;
  animation-duration: 3s;
  }
.hidden-2 {
  animation: fadeOut forwards;
  animation-duration: 2s;
}
.hidden-3 {
  animation: fadeOut2 forwards;
  animation-duration: 4s;
}
.hidden-4 {
  animation: fadeOut2 forwards;
  animation-duration: 1s;
}
.hidden-5 {
  animation: fadeOut1 forwards;
  animation-duration: 5s;
}

@keyframes fadeOut {
  0% {opacity: 1; transform: translate(0, 0);}
  100% {opacity: 0; transform: translate(0, 160%);}
}
@keyframes fadeOut1 {
  0% {opacity: 1; transform: translate(0, 0);}
  100% {opacity: 0; transform: translate(30%, 160%);}
}
@keyframes fadeOut2 {
  0% {opacity: 1; transform: translate(0, 0);}
  100% {opacity: 0; transform: translate(-30%, 160%);}
}

.center-content{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.tech-continer{
  display: flex;
  justify-content: space-around;

  flex-wrap: wrap;
  width: 100%;
  max-width: 80rem;

}
.technology-used{
  display: flex;
  justify-content: center;
  font-size: larger;
  font-family: monospace;
  margin: 2rem;
  padding: 2rem;
  border-bottom: #ffffff solid 1px;
  max-width: 75rem;
  width: 100%;
}
.name-container{
  display: flex;
  justify-content: center;
  background-image: linear-gradient(176deg, #18181B 70%, #27272A calc(70% + 2px)) ;
  height: 15rem;
  width:100%;
  animation: slideDown 1s ease-in-out forwards;
}
@keyframes slideDown {
  0% { transform: translate(0, -40%);}
  100% { transform: translate(0, 0);}
}
.my-name {
  color: hsl(0, 0%, 100%);
  font-size: 50px!important;
  font-weight: bold!important;
  font-family: monospace;
  letter-spacing: 7px!important;
  cursor: pointer;
  text-transform: uppercase;
  padding: 64px;
  font-size: 50px;
}

@keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 2000px;
  }
  100% {
    background-position: 2000px;
  }
}
.my-card{
  margin-bottom: 1rem;
}
.my-card:hover{
  animation: select forwards .5s;
  box-shadow: 5.0px 10.1px 10.1px hsl(0deg 0% 0% / 0.34);
}
@keyframes select {
  0% { transform: translate(0, 0);}
  100% { transform: translate(0, -3%);}
}
.about-container{
  background-color: #18181B;
  padding: 3rem;
  border-radius: 15px;
  max-width: 40rem;
  
  /* font-weight: bold!important; */
  font-family: monospace;
  font-size: calc((2vw + 2vh + 1vmin)/2);

}
@media (width <= 650px) {
  .about-container{
      padding: 1.5rem; 
  }
}

.chip-container{
  max-width: 70rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 1vh;
  font-family: monospace;
  font-size: 1rem;

}
.fliter-and-chips{
  width: fit-content;
  padding: .7rem;
  background-color: #18181B;
  border-radius: 15px;

}
.chip{

  user-select: none;
  -webkit-user-select: none; /*Safari*/
  -moz-user-select: none; /*Firefox*/
}
.filter{
  margin-bottom: 1vh;
}
.chip:hover{
  animation: buttonAnime .7s forwards;
}
.chip:not(:hover){
  animation: buttonAnimeBack .7s backwards;
}
@keyframes buttonAnime {
  0% { box-shadow: none;}
  100% {   box-shadow: #0067dd 5px 5px, #005BC4 10px 10px, #004493 15px 15px, #002E62 20px 20px;
  }
}
@keyframes buttonAnimeBack {
  0% {   box-shadow: #0067dd 5px 5px, #005BC4 10px 10px, #004493 15px 15px, #002E62 20px 20px;}
  100% { box-shadow: none;}
}

.flex-start{
  display: flex;
  justify-content: flex-start;
}
.loading{
  min-height: 40vh;
  padding-top: 15vh;
}