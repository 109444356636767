.footer-container{
    padding: 8rem;
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 15rem;
    background-image: linear-gradient(176deg,#27272A  20%, #18181B calc(20% + 2px)) ;
    font-size: 50px!important;
    font-weight: bold!important;
    font-family: monospace;
    letter-spacing: 7px!important;
}